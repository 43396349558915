import ky from 'ky';
import { CampaignFeatures } from '../../library/interfaces';
import useCommonRequestOptions from '../useCommonRequestOptions';
import store from '../../store';

export default function useCampaignPhasedAccess() {
  const { getCommonRequestOptions } = useCommonRequestOptions();

  function getUserAccessToActiveCampaigns(): Promise<CampaignFeatures> {
    return ky
      .get('campaigns-access', {
        ...getCommonRequestOptions('enrollment-campaigns', { token: store.getState().auth.accessToken }),
      })
      .json();
  }

  return { getUserAccessToActiveCampaigns };
}
