import { action } from 'typesafe-actions';
import {
  AccountBalance,
  Seat,
  SpendingLimitResponse,
  User,
  CampaignFeatures,
  Voucher,
  PreferredPaymentMethod,
} from '../../library/interfaces';
import {
  INIT_SEATS,
  INIT_USER,
  INIT_VOUCHERS,
  RESET_USER_STATE,
  SELECT_SEAT,
  SET_BALANCE,
  SET_CAMPAIGN_PHASED_ACCESS,
  SET_INITIAL_STATE,
  SET_PREFERRED_PAYMENT_METHODS,
  SET_LIMIT,
  SET_USER_LOADING,
} from './constants';

export const initSeat = (seats: Seat[]) => action(INIT_SEATS, seats);
export const selectSeat = (seat: Seat) => action(SELECT_SEAT, seat);
export const initVouchers = (vouchers: Voucher[]) => action(INIT_VOUCHERS, vouchers);
export const initUser = (user: User) => action(INIT_USER, user);
export const setAccountBalance = (balance: AccountBalance) => action(SET_BALANCE, balance);
export const setInitialState = (
  user: User,
  seats: Seat[],
  currentSeat: Seat,
  user_payment_preferences?: PreferredPaymentMethod[]
) => action(SET_INITIAL_STATE, { user, seats, currentSeat, user_payment_preferences });
export const resetUser = () => action(RESET_USER_STATE);
export const setLimit = (spendingLimits: SpendingLimitResponse) => action(SET_LIMIT, spendingLimits);
export const setCampaignPhasedAccess = (campaignsAccess: CampaignFeatures) =>
  action(SET_CAMPAIGN_PHASED_ACCESS, campaignsAccess);
export const setUserLoading = (loading: boolean) => action(SET_USER_LOADING, loading);
export const setPreferredPaymentMethods = (user_payment_preferences: PreferredPaymentMethod[]) =>
  action(SET_PREFERRED_PAYMENT_METHODS, { user_payment_preferences });
